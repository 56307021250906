<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Utilisateurs</h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                @click="goCreateUser"
            >
                <b-icon-person-plus-fill /> Créer un utilisateur
            </b-button>
        </div>

        <div class="k-page-body">
            <TableUsers />
        </div>
    </div>
</template>
<script>
import TableUsers from "@/components/model/user/TableUsers";

export default {
    components: {
        TableUsers,
    },

    methods: {
        goCreateUser: function() {
            this.$router.push({ name: "AdminCreateUser" });
        },
    },
};
</script>
